'use strict';

/** Event-driven HTML5 single-stream audio playback
  *
  * Allows audio to be triggered from anywhere in a page, with context provided,
  * and publishes UI events for other components to consume.
  */
const events = require('../core/events');
const throttledDispath = require('../helpers/eventthrottler');
/* HACK: Not using HLS yet so don't bundle dep.
const Hls = require('hls.js/dist/hls.light');
// HACK: Only use NATIVE_HLS if hls.js isn't supported. Android Chrome doesn't handle Audio HLS
const NATIVE_HLS = !Hls.isSupported() && !!new Audio().canPlayType('application/vnd.apple.mpegurl');
*/
const STREAM_FILE_REGEX = /^https:\/\/(wwwcdn|stream)\.bff\.fm/; // HACK: const STREAM_FILE_REGEX = /\.m3u8$/;

/*
 * @param src URL An MP3 file to be played
 * @param volume number The initial volume for the track
 *
 * NB “isStream” is treated as a global — e.g. if this AudioSource believes it is a stream
 *    and not a standalone, finite track, it will poll for live metadata from Creek.
 */
function AudioSource(src, volume) {
  const self = this;
  var audioElement;
  var maintainedVolume;

  Object.defineProperty(this, 'id', {
    get: function () { return src.replace(/\W/g, ''); }
  });

  Object.defineProperty(this, 'src', {
    get: function () { return src; }
  });

  Object.defineProperty(this, 'isStream', {
    get: function () { return STREAM_FILE_REGEX.test(src); }
  });

  // hls.js is not supported on platforms that do not have Media Source Extensions (MSE) enabled.
  // When the browser has built-in HLS support (check using `canPlayType`), we can provide an HLS
  // manifest (i.e. .m3u8 URL) directly to the video element through the `src` property.
  const hlsHelper = undefined; /* HACK: Not using HLS yet:
    this.isStream && !NATIVE_HLS && Hls.isSupported() ? new Hls({
    autoStartLoad: true,
    liveDurationInfinity: true,
    debug: true }) : undefined;
  */

  Object.defineProperty(this, 'audioElement', {
    get: function () {
      if (!audioElement) {
        this.audioElement = this.src;
      }
      return audioElement;
    },
    set: function (url) {
      if (url === null) {
        if (hlsHelper) {
          hlsHelper.detachMedia();
          hlsHelper.stopLoad();
        } else {
          // TODO: Determine which browsers need this to stop loading?
          audioElement.src = '';
        }
        audioElement = null;
        return;
      }

      audioElement = new Audio();
      audioElement.autoplay = false;
      audioElement.preload = 'metadata';
      audioElement.volume = this.volume;
      audioElement.title = 'BFF.fm'; // Initial title for iOS lock screen

      this.bindAudioEvents(audioElement);
      // TODO: Handle error case when audio cannot be played at all

      if (hlsHelper) {
        // If not native HLS (not Safari or Edge):
        hlsHelper.attachMedia(audioElement);
        hlsHelper.loadSource(url);
      } else {
        // Otherwise, throw the URL to the <audio> element like any other
        audioElement.src = [
          url,
          // HACK: Include cacheBust to workaround Firefox repeating-audio issue with streams
          this.isStream ? '?_cacheBust=' + Math.floor(Math.random() * 50000) : ''
        ].join('');
      }
    }
  });

  Object.defineProperty(this, 'volume', {
    get: function () { return maintainedVolume; },
    set: function (newVolume) {
      var limited = Math.max(0, Math.min(newVolume, 1));

      if (limited === maintainedVolume) { return; }

      maintainedVolume = limited;
      if (audioElement) { audioElement.volume = maintainedVolume; }

      document.dispatchEvent(new CustomEvent(events.audioSourceVolumeChanged, {
        detail: { newValue: maintainedVolume }
      }));
    }
  });

  Object.defineProperty(this, 'baseEventDetail', {
    get: function () {
      return {
        id: this.id,
        src: this.src,
        time: (audioElement && audioElement.currentTime) || 0,
        duration: this.isStream ? Infinity : ((audioElement && audioElement.duration) || Infinity),
        isStream: this.isStream
      };
    }
  });

  // Return a position (in seconds) for this file, depicting the end position of the file when seeking
  Object.defineProperty(this, 'maxPosition', {
    get: function () {
      if (audioElement.duration !== Infinity) {
        return audioElement.duration;
      }
      const buffers = audioElement.buffered.length;
      return audioElement.buffered.end(buffers - 1);
    }
  });

  Object.defineProperty(this, 'playing', {
    get: function () { return audioElement && !audioElement.paused; }
  });

  /* <audio>.title is used by iOS to display playback info on the lock screen */
  Object.defineProperty(this, 'title', {
    get: function getPlaybackTitle() { return (audioElement && audioElement.title) || ''; },
    set: function setPlaybackTitle(str) {
      if (audioElement) {
        if (this.title === str) {
          return;
        }
        audioElement.title = str;

        // HACK: Force iOS to update metadata?
        if (!audioElement.paused) {
          audioElement.play();
        }
      }
    }
  });

  document.addEventListener(events.audioSourceMetadataUpdate, function audioMetadataUpdated(e) {
    if (!audioElement) {
      return;
    }
    if (!e.detail) {
      return;
    }

    if (self.isStream && STREAM_FILE_REGEX.test(e.detail.src)) {
      self.updateMetadata(e.detail.metadata);
    }

    if (audioElement.src === e.detail.src) {
      self.updateMetadata(e.detail.metadata);
    }
  });

  // Set input data
  this.volume = volume;
}

AudioSource.prototype.updateMetadata = function updateTrackMetadata(metadata) {
  // If no unique track info
  var stringParts = [];

  if (!metadata.title || metadata.title === metadata.show) {
    stringParts = [metadata.album, metadata.show, 'BFF.fm'];
  } else {
    stringParts = [metadata.title, metadata.artist, metadata.show, 'BFF.fm'];
  }

  this.title = stringParts.filter(function (item) { return !!item; }).join(' - ');
  return this.title;
  // TODO: Find out if there's a way to set the art from `metadata.image`
};

AudioSource.prototype.play = function play() {
  document.dispatchEvent(new CustomEvent(events.audioSourceLoading, {
    detail: this.baseEventDetail
  }));
  this.audioElement.play();
};

AudioSource.prototype.pause = function pause() {
  if (!this.playing) {
    return;
  }

  this.audioElement.pause();

  if (this.isStream) {
    // HACK: If it's a stream, null out the player to stop background buffering
    this.audioElement = null;
  }
};

AudioSource.prototype.seek = function seek(timestamp) {
  if (Number.isNaN(timestamp)) {
    return;
  }

  const jumpTo = Math.max(0, Math.min(timestamp, this.maxPosition));
  this.audioElement.currentTime = jumpTo;
};

AudioSource.prototype.skip = function skip(amount) {
  if (Number.isNaN(amount)) {
    return;
  }
  this.seek(this.audioElement.currentTime + amount);
};

AudioSource.prototype.bindAudioEvents = function bindAudioEvents(audioElement) {
  var self = this;
  // Simplified event model for AudioSource

  // 1. audioSourceLoading
  // 2. audioSourcePlaying
  // 2a. audioSourceTimeUpdate
  // 2b. audioSourceMetadataUpdate
  // 3. audioSourcePaused
  // 4. audioSourceResumed
  // 5. audioSourceStalled
  // 6. audioSourceEnded

  // We don't care about the file loading
  // audioElement.addEventListener('loadstart', function () {});

  audioElement.addEventListener('playing', function () {
    document.dispatchEvent(new CustomEvent(events.audioSourcePlaying, {
      detail: self.baseEventDetail
    }));
  });
  audioElement.addEventListener('pause', function () {
    document.dispatchEvent(new CustomEvent(events.audioSourcePaused, {
      detail: self.baseEventDetail
    }));
  });
  audioElement.addEventListener('stalled', function () {
    document.dispatchEvent(new CustomEvent(events.audioSourceStalled, {
      detail: self.baseEventDetail
    }));
  });
  audioElement.addEventListener('ended', function () {
    document.dispatchEvent(new CustomEvent(events.audioSourceEnded, {
      detail: self.baseEventDetail
    }));
  });

  audioElement.addEventListener('timeupdate', function () {
    const throttleKey = self.id + events.audioSourceTimeUpdate;
    throttledDispath(throttleKey, new CustomEvent(events.audioSourceTimeUpdate, {
      detail: self.baseEventDetail
    }));
  });

  audioElement.addEventListener('abort', function () {
    document.dispatchEvent(new CustomEvent(events.audioSourcePaused, {
      detail: self.baseEventDetail
    }));
  });

  // TODO: Unsure if these should get echoed out of this object or just handled by the manager
  audioElement.addEventListener('error', function () {
    // console.log('error', e);
  });

  audioElement.addEventListener('volumechange', function () {
    self.volume = self.audioElement.volume;
  });
};

module.exports = AudioSource;
