'use strict';

const events = require('../core/events');
const forgeUiComponent = require('../framework/ui-component');

var AdvancedPlaybackControls = forgeUiComponent('AdvancedPlaybackControls', {
  paused: 'is-paused',
  streamingLive: 'is-streamingLive',
  playingArchive: 'is-playingArchive'
}, function AdvancedPlaybackControlsConstructor() {
  var self = this;

  document.addEventListener(events.audioSourcePlaying, function (e) {
    if (e.detail.duration === Infinity) {
      self.setPlaybackState(AdvancedPlaybackControls.STREAMING);
    } else {
      self.setPlaybackState(AdvancedPlaybackControls.ARCHIVE);
    }
  });

  document.addEventListener(events.audioSourceTimeUpdate, function (e) {
    if (e.detail.duration === Infinity) {
      self.setPlaybackState(AdvancedPlaybackControls.STREAMING);
    } else {
      self.setPlaybackState(AdvancedPlaybackControls.ARCHIVE);
    }
  });
});

AdvancedPlaybackControls.prototype.setPlaybackState = function setPlaybackState(state) {
  if (state === AdvancedPlaybackControls.ARCHIVE) {
    this.playingArchive = true;
    this.paused = false;
    this.streamingLive = false;
    return;
  }

  if (state === AdvancedPlaybackControls.STREAMING) {
    this.streamingLive = true;
    this.paused = false;
    this.playingArchive = false;
    return;
  }

  this.playingArchive = false;
  this.streamingLive = false;
  this.paused = true;
};

AdvancedPlaybackControls.ARCHIVE = 1;
AdvancedPlaybackControls.STREAMING = 2;
AdvancedPlaybackControls.PAUSED = 3;

module.exports = AdvancedPlaybackControls;
